
import { getCreditBureaus, sendSelectedCreditBureaus } from '@/api/common';
import { finishFlow } from '@/api/flowSession';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseCheckBox from '@/components/baseComponents/BaseCheckBox.vue';
import BaseCirclePreloader from '@/components/baseComponents/BaseCirclePreloader.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { getLastPageNew, getNextPage, goToDashBoard } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ListItemBureau from '@/components/lists/ListItemBureau.vue';
import { IBureau } from '../../models';

const BasePopupForPages = () => import('@/components/baseComponents/BasePopupForPages.vue');

@Component({
  components: {
    TitleDescriptionUniversal,
    ListItemBureau,
    BaseCirclePreloader,
    BaseCheckBox,
    BaseButton,
    BasePopupForPages,
  },
  computed: {
    ...mapGetters('steps', ['isPreviousPageExist']),
  },
})
export default class BureausPage extends Vue {
  public isPreviousPageExist!: boolean;

  bureauList = [];
  timerIdServer = 0;
  isButtonPreloaderShow = false;
  isShowPrivacyPolicy = false;
  isShowTermsOfUse = false;

  get isHidePreloader(): boolean {
    return this.bureauList.length > 0;
  }
  get bureausPageNotValid(): boolean {
    return this.$store.state.bureaus.bureausSelected.length === 0 || !this.agreeWithTermsBureaus;
  }
  get isMainCheckBoxActive(): boolean {
    return this.$store.state.bureaus.bureausSelected.length > 0;
  }
  set isMainCheckBoxActive(value: boolean) {
    if (this.isMainCheckBoxActive) {
      this.$store.commit('bureaus/deleteAllBureaus');
    } else {
      const allBureausIds = this.bureauList.map((bureauObject: any) => bureauObject.id);
      this.$store.commit('bureaus/selectAllBureaus', allBureausIds);
    }
  }
  get isAllBureauSelected(): string {
    const selectedBureausObjects = this.bureauList.filter(
      (bureau: IBureau) => this.$store.state.bureaus.bureausSelected.indexOf(bureau.id) > -1,
    );
    if (selectedBureausObjects.length > 0 && selectedBureausObjects.length < this.bureauList.length) return 'notAllSelected';
    if (selectedBureausObjects.length === this.bureauList.length) return 'allSelected';
    return '';
  }
  get actualLinks(): any {
    const selectedBureausObjects = this.bureauList.filter(
      (bureau: IBureau) => this.$store.state.bureaus.bureausSelected.indexOf(bureau.id) > -1,
    );
    const termOfUseUrl = selectedBureausObjects
      .map((bureau: any) => bureau.termOfUseUrl)
      .filter((element) => element !== null)
      .pop();

    const privacyPolicyUrl = selectedBureausObjects.map((bureau: any) => bureau.privacyPolicyUrl || '');
    const bureausLinks = ['creditsafe', 'lexisnexis'];
    const privacyPolicyUrlObj = Object.fromEntries(
      bureausLinks
        .map((item) => [item, privacyPolicyUrl.find((link) => link.includes(item))])
        .filter((element) => element[1]),
    );
    return { privacyPolicyUrlObj, termOfUseUrl };
  }
  get agreeWithTermsBureaus(): boolean {
    return this.$store.state.bureaus.agreeWithTermsBureaus;
  }
  set agreeWithTermsBureaus(value: boolean) {
    this.$store.commit('bureaus/setAgreeWithTermsBureaus', value);
  }

  lastButtonAction(): void {
    this.$router.push({ name: getLastPageNew() });
  }

  async nextButtonAction(): Promise<void> {
    this.isButtonPreloaderShow = true;
    try {
      const currentFlow = this.$store.getters['flowStages/getCurrentFlow'];
      await sendSelectedCreditBureaus(this.$store.state.bureaus.bureausSelected);

      if (currentFlow === '107') {
        this.$router.push({ name: getNextPage() });
      } else if (currentFlow === '302') {
        finishFlow();
        goToDashBoard('dashboard')
        // this.$router.push({ name: 'PortalPage' });
      } else {
        goToDashBoard('dashboard')
        // this.$router.push({ name: 'PortalPage' });
      }
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isButtonPreloaderShow = false;
    }
  }
  async getBureaus(): Promise<void> {
    try {
      this.timeOutServerError();
      this.bureauList = (await getCreditBureaus()) as any;
      clearTimeout(this.timerIdServer);
    } catch (error: any) {
      this.popupError(error.response.data.message);
    }
  }
  timeOutServerError(): void {
    this.timerIdServer = setTimeout(() => {
      this.popupError('the server is not responding');
    }, 10000);
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }
  openPopup(nameOfAgreement: string): void {
    if (nameOfAgreement === 'PrivacyPolicy') this.isShowPrivacyPolicy = true;
    if (nameOfAgreement === 'TermsOfUse') this.isShowTermsOfUse = true;
  }
  closePopup(): void {
    this.isShowPrivacyPolicy = false;
    this.isShowTermsOfUse = false;
  }

  mounted(): void {
    this.getBureaus();
  }
}
