
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IBureau } from '../../models';
import BaseCheckBox from '../baseComponents/BaseCheckBox.vue';

@Component({
  components: {
    BaseCheckBox,
  },
})
export default class ListItemBureau extends Vue {
  @Prop({ default: null }) bureau!: IBureau;

  get isBureauSelected(): boolean {
    return this.$store.state.bureaus.bureausSelected.includes(this.bureau.id);
  }
  set isBureauSelected(value: boolean) {
    if (!this.isBureauSelected) this.$store.commit('bureaus/addBureaus', this.bureau.id);
    else this.$store.commit('bureaus/deleteBureaus', this.bureau.id);
  }
}
